<template>
  <v-card>
    <validation-observer v-slot="{ invalid }">
      <v-card-title class="text-center">
        <slot name="title"> Cadastro de Regra </slot>
      </v-card-title>
      <v-card-text>
        <v-form>
          <validation-provider
            name="fornecedor"
            rules="required"
            v-slot="{ errors }"
          >
            <v-autocomplete
              v-model="form.fornecedor"
              :error-messages="errors"
              :items="fornecedores"
              :item-text="
                (item) => item.business_name + ` - ${item.cnpj || item.cpf}`
              "
              item-value="public_id"
              return-object
              @change="setTerminais"
              label="Fornecedor"
            />
          </validation-provider>
          <validation-provider
            name="terminal"
            rules="required"
            v-slot="{ errors }"
          >
            <v-autocomplete
              v-model="form.terminal"
              :error-messages="errors"
              :items="terminais"
              item-text="business_name"
              item-value="public_id"
              label="Terminal"
            />
          </validation-provider>
          <validation-provider
            name="servico"
            rules="required"
            v-slot="{ errors }"
          >
            <v-autocomplete
              v-model="form.servico"
              :error-messages="errors"
              :items="servicos"
              item-text="descricao"
              item-value="id"
              label="Serviço"
            />
          </validation-provider>
          <validation-provider
            name="prestador"
            rules="required"
            v-slot="{ errors }"
          >
            <v-autocomplete
              v-model="form.prestador"
              :error-messages="errors"
              :items="prestadoresServico"
              item-text="business_name"
              item-value="public_id"
              label="Prestador"
            />
          </validation-provider>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end pa-8">
        <slot name="button" :invalid="invalid" />
      </v-card-actions>
    </validation-observer>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import contratoAPI from '@/services/tenants/contratos'

import Perfil from '@/utils/perfil'

export default {
  name: 'RegraForm',
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      terminais: [],
    }
  },
  computed: {
    ...mapState('auth', ['empresaAtual']),
    ...mapState('fornecedores', ['fornecedores']),
    ...mapState('patio', ['servicos']),
    ...mapState('contratos', ['contratos']),
    ...mapGetters('contratos', ['prestadoresServico']),
  },
  methods: {
    ...mapActions('fornecedores', ['getFornecedores']),
    ...mapActions('patio', ['getServicos']),
    ...mapActions('contratos', ['getContratosPrestadores']),
    ...mapActions('template', ['errorMessage']),

    async setTerminais({ fornecedor_id }) {
      try {
        this.terminais = await contratoAPI.getContratos(fornecedor_id, {
          perfil: Perfil.Terminal,
        })
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },
  },
  async created() {
    try {
      const {
        fornecedor: { fornecedor_id },
      } = this.form
      if (fornecedor_id) {
        await this.setTerminais({ fornecedor_id })
      }
      await this.getServicos({
        proprietario: this.empresaAtual.public_id,
      })
      await this.getFornecedores()
      await this.getContratosPrestadores()
    } catch (error) {
      if (error.response) {
        this.errorMessage(error.response.data)
        return
      }
      if (error.message) {
        this.errorMessage(error.message)
        return
      }
      this.errorMessage(error)
    }
  },
}
</script>
