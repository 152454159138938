<template>
  <div>
    <confirm-dialog @confirm="confirmDelete" />
    <div class="mt-4">
      <v-card-title v-if="canList">
        Filtro
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisa"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="regras"
        :search="search"
        class="border"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Regras de Prestadores de Serviços</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-icon v-if="canList" medium @click="getItems" title="Atualizar"
              >mdi-refresh</v-icon
            >
            <v-spacer></v-spacer>
            <v-btn
              v-if="canCreate"
              color="primary"
              dark
              class="mb-2"
              @click="$emit('cadastrar')"
              >Adicionar</v-btn
            >
          </v-toolbar>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at | toLocaleDateTimeString }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon v-if="canUpdate" @click="$emit('editar', item)" class="mx-2">
            mdi-pencil
          </v-icon>
          <v-icon v-if="canDelete" color="error" @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
        <template #no-data v-if="!canList">
          <span class="red--text"
            >Você não tem permissão para visualizar as informações desta
            tela</span
          >
        </template>
      </v-data-table>
    </div>
  </div>
</template>


<script>
import { mapActions, mapState } from 'vuex'

import { toLocaleDateTimeString } from '@/mixins/convertion'

import ConfirmDialog from '@/components/template/ConfirmDialog'

import { BasicPermissions, Sequences } from '@/utils/permissions'

export default {
  name: 'ListagemRegras',
  data() {
    return {
      search: '',
      headers: [
        { text: 'Fornecedor', value: 'fornecedor.business_name' },
        { text: 'Terminal', value: 'terminal.business_name' },
        { text: 'Serviço', value: 'servico.descricao' },
        { text: 'Prestador', value: 'prestador.business_name' },
        { text: 'Adicionado Em', value: 'created_at' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      item: null,
    }
  },
  components: {
    ConfirmDialog,
  },
  filters: {
    toLocaleDateTimeString,
  },
  computed: {
    ...mapState('regraPrestadorServico', ['regras']),

    permission() {
      return Sequences.RegrasPrestadores.toString()
    },

    canList() {
      return this.$canDo(
        BasicPermissions.VISUALIZAR,
        this.permission
      )
    },
    canCreate() {
      return this.$canDo(
        BasicPermissions.CADASTRAR,
        this.permission
      )
    },
    canUpdate() {
      return this.$canDo(
        BasicPermissions.EDITAR,
        this.permission
      )
    },
    canDelete() {
      return this.$canDo(
        BasicPermissions.EXCLUIR,
        this.permission
      )
    },
  },
  methods: {
    ...mapActions('regraPrestadorServico', [
      'getRegrasPrestadores',
      'deleteRegraPrestador',
    ]),
    ...mapActions('template', [
      'errorMessage',
      'successMessage',
      'setDialogMessage',
    ]),

    deleteItem(item) {
      this.item = item
      this.setDialogMessage({
        title: 'Confirmar exclusão?',
        message: 'Deseja mesmo excluir essa regra?',
      })
    },

    async confirmDelete() {
      try {
        await this.deleteRegraPrestador({
          regraPublicID: this.item.public_id,
        })
        this.successMessage('Regra excluída com sucesso!')
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },

    async getItems() {
      try {
        await this.getRegrasPrestadores()
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },
  },
  async created() {
    if (this.canList) {
      await this.getItems()
    }
  },
}
</script>
