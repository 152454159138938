<template>
  <regra-form :form="form">
    <template #button="{ invalid }">
      <v-btn color="primary" :disabled="invalid" @click="save">Cadastrar</v-btn>
    </template>
  </regra-form>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import RegraForm from './RegraForm.vue'

export default {
  name: 'CadastroRegra',
  data() {
    return {
      form: {
        fornecedor: '',
        terminal: '',
        servico: '',
        prestador: '',
      },
    }
  },
  components: { RegraForm },
  computed: {
    ...mapState('auth', ['empresaAtual']),
  },
  methods: {
    ...mapActions('template', ['errorMessage', 'successMessage']),
    ...mapActions('regraPrestadorServico', ['addRegraPrestador']),

    cleanForm() {
      this.form.fornecedor = ''
      this.form.terminal = ''
      this.form.servico = ''
      this.form.prestador = ''
    },

    async save() {
      try {
        await this.addRegraPrestador({
          dados: {
            ...this.form,
            fornecedor: this.form.fornecedor.public_id,
            proprietario: this.empresaAtual.public_id,
          },
        })
        this.$emit('salvo')
        this.successMessage('Regra adicionada com sucesso!')
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },
  },
}
</script>
