<template>
  <regra-form :form="form">
    <template #button="{ invalid }">
      <v-btn color="primary" :disabled="invalid" @click="save">Salvar</v-btn>
    </template>
  </regra-form>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import RegraForm from './RegraForm.vue'

export default {
  name: 'EdicaoRegra',
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  components: { RegraForm },
  computed: {
    ...mapState('auth', ['empresaAtual']),
  },
  methods: {
    ...mapActions('template', ['errorMessage', 'successMessage']),
    ...mapActions('regraPrestadorServico', ['editRegraPrestador']),

    async save() {
      try {
        await this.editRegraPrestador({
          dados: {
            ...this.form,
            proprietario: this.form.proprietario.public_id,
            fornecedor: this.form.fornecedor.public_id,
            terminal: this.form.terminal.public_id || this.form.terminal,
            servico: this.form.servico.id || this.form.servico,
            prestador: this.form.prestador.public_id || this.form.prestador,
          },
        })
        this.$emit('salvo')
        this.successMessage('Regra alterada com sucesso!')
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },
  },
}
</script>
