<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard>
          <listagem-regras @cadastrar="cadastrar" @editar="editar" />
          <v-dialog v-model="showModal" max-width="500px">
            <cadastro-regra
              v-if="showCadastro"
              @salvo="showCadastro = false"
              ref="cadastro"
            />
            <edicao-regra
              v-else-if="showEdicao"
              @salvo="showEdicao = false"
              :form="itemAEditar"
            />
          </v-dialog>
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ListagemRegras from '@/components/administrativo/regraPrestadorServico/Listagem'
import CadastroRegra from '@/components/administrativo/regraPrestadorServico/Cadastro'
import EdicaoRegra from '@/components/administrativo/regraPrestadorServico/Edicao'

export default {
  data() {
    return {
      page: {
        title: 'Regras de Prestadores de Serviços',
      },
      breadcrumbs: [
        {
          text: 'Administrativo',
          disabled: false,
        },
        {
          text: 'Regras de Prestadores',
          disabled: true,
        },
      ],
      showCadastro: false,
      showEdicao: false,
      itemAEditar: {
        fornecedor: '',
        terminal: '',
        servico: '',
        prestador: '',
      },
    }
  },
  computed: {
    showModal: {
      get() {
        return this.showCadastro || this.showEdicao
      },
      set(val) {
        this.showCadastro = val
        this.showEdicao = val
      },
    },
  },
  components: { ListagemRegras, CadastroRegra, EdicaoRegra },
  methods: {
    cadastrar() {
      this.showCadastro = true
      this.$nextTick(() => this.$refs.cadastro.cleanForm())
    },
    editar(item) {
      this.showEdicao = true
      this.itemAEditar = { ...item }
    },
  },
}
</script>
