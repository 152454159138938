var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card-title',{staticClass:"text-center"},[_vm._t("title",[_vm._v(" Cadastro de Regra ")])],2),_c('v-card-text',[_c('v-form',[_c('validation-provider',{attrs:{"name":"fornecedor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"error-messages":errors,"items":_vm.fornecedores,"item-text":function (item) { return item.business_name + " - " + (item.cnpj || item.cpf); },"item-value":"public_id","return-object":"","label":"Fornecedor"},on:{"change":_vm.setTerminais},model:{value:(_vm.form.fornecedor),callback:function ($$v) {_vm.$set(_vm.form, "fornecedor", $$v)},expression:"form.fornecedor"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"terminal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"error-messages":errors,"items":_vm.terminais,"item-text":"business_name","item-value":"public_id","label":"Terminal"},model:{value:(_vm.form.terminal),callback:function ($$v) {_vm.$set(_vm.form, "terminal", $$v)},expression:"form.terminal"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"servico","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"error-messages":errors,"items":_vm.servicos,"item-text":"descricao","item-value":"id","label":"Serviço"},model:{value:(_vm.form.servico),callback:function ($$v) {_vm.$set(_vm.form, "servico", $$v)},expression:"form.servico"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"prestador","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"error-messages":errors,"items":_vm.prestadoresServico,"item-text":"business_name","item-value":"public_id","label":"Prestador"},model:{value:(_vm.form.prestador),callback:function ($$v) {_vm.$set(_vm.form, "prestador", $$v)},expression:"form.prestador"}})]}}],null,true)})],1)],1),_c('v-card-actions',{staticClass:"justify-end pa-8"},[_vm._t("button",null,{"invalid":invalid})],2)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }